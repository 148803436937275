import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      © 2023 - TODOS LOS DERECHOS RESERVADOS. COOPERATIVA DE SERVICIOS PÚBLICOS
      DE COLONIA CAROYA Y JESÚS MARÍA LTDA.
    </footer>
  );
};

export default Footer;
