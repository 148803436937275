import "./main.css";
import fraseCoop from "../../images/frasecoop.png";

const Main = () => {
  return (
    <div className="main-container">
      <div className="img-container">
        <img alt="img" src={fraseCoop} />
      </div>
      <h1>Política de privacidad de APP ReclamosCoop</h1>
      <h2>Información que recopilamos</h2>

      <p>
        ReclamosCoop no recopila ningún tipo de información. Se solicita un
        usuario y clave, que le hemos entregado antes al usuario para que la
        utilice.
      </p>
      <p>
        Información de uso: ReclamosCoop sólo recopila la información que el
        usuario ingrese como observaciones del cierre de los reclamos.
      </p>

      <h2>Cómo usamos su información</h2>
      <p>
        ReclamosCoop no comparte su información personal con terceros, excepto
        en las siguientes circunstancias:
      </p>
      <ul>
        <li>
          Para cumplir con la ley: ReclamosCoop puede compartir su información
          con terceros para cumplir con una ley o una orden judicial.
        </li>
        <li>
          Para proteger nuestros derechos: ReclamosCoop puede compartir su
          información con terceros para proteger nuestros derechos, como en caso
          de una disputa legal.
        </li>
      </ul>

      <h2>Seguridad de su información</h2>
      <p>
        ReclamosCoop al no tener información personal, del usuario que utiliza
        la App, no se necesita tomar medidas para proteger su información
        personal. Aun así toda la información proporcionada esta protegida con
        las siguientes medidas:
      </p>
      <ul>
        <li>
          Almacenamiento seguro: ReclamosCoop almacena su información en
          servidores seguros.
        </li>
        <li>
          Control de acceso: Solo los empleados autorizados de ReclamosCoop
          tienen acceso a su información.
        </li>
      </ul>

      <h2>Sus derechos</h2>
      <p>
        Para conocer sobre sus derechos, puede ponerse en contacto con
        ReclamosCoop en{" "}
        <a href="mailto:cooperativaccjm@gmail.com">cooperativaccjm@gmail.com</a>{" "}
        o +54 3525 466201.
      </p>

      <h2>Cambios a esta política de privacidad</h2>
      <p>
        ReclamosCoop puede modificar esta política de privacidad de vez en
        cuando. Si realizamos cambios significativos a esta política, le
        notificaremos por correo electrónico o mediante un aviso en la
        aplicación.
      </p>

      <h2>Cómo contactarnos</h2>
      <ul>
        <li>
          Correo electrónico:{" "}
          <a href="mailto:cooperativaccjm@gmail.com">
            cooperativaccjm@gmail.com
          </a>
        </li>
        <li>Teléfono: +54 3525 466201</li>
      </ul>

      <br />
      <br />
      <h4>ReclamosCoop, 2023</h4>
    </div>
  );
};

export default Main;
