import "./navbar.css";
import imgNav from "../../images/coop.png";

const Navbar = () => {
  return (
    <nav className="nav">
      <a href="https://www.coop5.com.ar">
        <img alt="img" src={imgNav} />
      </a>
    </nav>
  );
};

export default Navbar;
